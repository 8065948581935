<template>
  <ValidationObserver ref="observer">
    <form class="StatusPageForm"
          @submit.prevent="submit">
      <div class="header page-header mb-0">General Settings</div>

      <base-textbox
        label="Title"
        ref="title"
        placeholder="Title of the status page"
        rules="max:255"
        v-model="statusPageData.title"
      />

      <base-textbox
        label="Custom domain name"
        placeholder="domain.com"
        rules="url|statusPageDomainValid"
        description="Create a CNAME DNS record for your domain and point it to app.pulsetic.com. Initial request to the status page will be a little bit longer because we will generate SSL certificate."
        v-model="statusPageData.domain"
      />
      <base-textbox
        label="Custom meta title"
        placeholder="Status Page | Pulsetic"
        description="Create a custom meta title for your status page."
        v-model="statusPageData.meta_title"
      />

      <template v-if="user.subscription_plan.price">
        <div class="header page-header mb-0">reCAPTCHA</div>

        <base-textbox
          v-model="statusPageData.site_key"
          placeholder="Site key"
          label="Site key"
          description="Insert your reCAPTCHA Site key to avoid the spam emails on your status page form."
        />

        <base-textbox
          v-model="statusPageData.secret_key"
          placeholder="Secret key"
          label="Secret key"
          description="Insert your reCAPTCHA Secret key."
        />
      </template>

      <base-textbox
        v-model="statusPageData.google_analytics_id"
        placeholder="G-xxxxxxxxxx"
        label="Google Analytics"
        description="Available only for a Custom Domain status page. Please use the format: G-xxxxxxxxxx."
      />

      <base-textbox
        label="Uptime threshold (%)"
        description="If a monitor uptime goes below this value it is highlighted yellow"
        rules="required|max_value:100"
        pattern="^(?!0\d)\d*(\.\d+)?$"
        v-model="uptimeThreshold"
      />

      <div class="d-flex mt-3">
        <base-checkbox label="Remove Branding"
                       v-model="statusPageData.remove_branding" :disabled="!user.subscription_plan.remove_branding"/>
        <span class="pro-label" v-if="!user.subscription_plan.remove_branding">TEAM</span>
      </div>
      <p class="checkbox-description">This feature removes any Pulsetic.com branding from the page.</p>

      <div class="d-flex">
        <base-checkbox label="Subscribe to updates"
                       v-model="statusPageData.subscribe_to_updates"
                       :disabled="!user.subscription_plan.subscribers_no"/>
        <span class="pro-label" v-if="!user.subscription_plan.subscribers_no">SOLO</span>
      </div>
      <p class="checkbox-description">This feature allows your visitors to subscribe for your service updates by
        email.</p>

      <div class="d-flex">
        <base-checkbox label="Private"
                       v-model="statusPageData.private" :disabled="!user.subscription_plan.password_protected"/>
        <span class="pro-label" v-if="!user.subscription_plan.password_protected">SOLO</span>
      </div>
      <p class="checkbox-description">This feature allows you to create password-protected status pages.</p>

      <div class="d-flex mt-3">
        <base-checkbox label="Noindex"
                       v-model="statusPageData.no_index" :disabled="!user.subscription_plan.no_index"/>
        <span class="pro-label" v-if="!user.subscription_plan.no_index">SOLO</span>
      </div>
      <p class="checkbox-description">This option adds a noindex meta tag, keeping your page out of search engines.</p>

      <div class="d-flex mt-3">
        <base-checkbox label="Show the uptime percentage as a whole number"
                       v-model="statusPageData.uptime_percentage_style"/>
      </div>
      <p class="checkbox-description">This option will remove the decimal numbers from the uptime percentage.</p>

      <div class="d-flex mt-3">
        <base-checkbox label="Show the status page's location tooltip"
                       v-model="statusPageData.show_location_tooltip"/>
      </div>
      <p class="checkbox-description">Detailed uptime by location will display on the status page.</p>

      <div class="d-flex mt-3">
        <base-checkbox label="Move offline monitors to the top"
                       v-model="statusPageData.offline_monitors_on_top"/>
      </div>
      <p class="checkbox-description">The website which are down, automatically go to the top of the status page.</p>

      <div v-if="statusPageData.private" style="padding-left:30px;max-width: 400px;">
        <base-textbox label="Set a Password" placeholder="Password" type="password" v-model="statusPageData.password"/>
      </div>

      <div class="toolbar">

        <base-button
          type="submit"
          ref="submit"
          class="submit success"
          :loading="saving"
        >
          {{ submitLabel }}
        </base-button>

        <base-button
          type="button"
          class="is-outlined"
          ref="cancel"
          color="light"
          @click="goToStatusPagesList"
        >
          Cancel
        </base-button>
      </div>

      <div v-show="false">
        <hint-limit-exceeded ref="limitExceeded"/>
      </div>

      <div v-if="error">
        <base-alert type="error">{{ error }}</base-alert>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import HintLimitExceeded from '@/components/Hints/HintStatusPagesLimitExceeded.vue'

import statusPagesApi from '@/api/statusPagesApi.js'
import timeZoneApi from '@/api/timeZonesApi.js'

import tippy from 'tippy.js'
import BaseCheckbox from '@/components/Base/BaseCheckbox'
import BaseAlert from '@/components/Base/BaseAlert'

const defaultUptimeThreshold = 0.9995

export default {
  components: {
    BaseAlert,
    BaseCheckbox,
    HintLimitExceeded
  },

  props: {
    mode: {
      required: false,
      type: String,
      default: 'create'
    },

    statusPage: {
      required: false,
      type: Object
    }
  },

  data () {
    return {
      statusPageData: {
        title: '',
        uptime_threshold: defaultUptimeThreshold,
        monitors: [],
        site_key: '',
        secret_key: '',
        meta_title: '',
        no_index: false
      },

      monitors: [],
      uptimeThreshold: 100,

      timeZones: [],
      selectedTimeZone: null,

      saving: false,

      limitExceededTippy: null,
      updateSuccessTippy: null,

      error: false
    }
  },

  async created () {
    if (this.mode === 'update') {
      this.statusPageData = { ...this.statusPage }
      this.uptimeThreshold = this.statusPage.uptime_threshold * 100

      this.monitors = this.statusPage.monitors.slice()
    }
  },

  mounted () {
    this.$refs.title.focus()

    this.updateButtonTippy = tippy(this.$refs.submit.$el, {
      content: 'Status page was successfully updated',
      arrow: false,
      placement: 'right',
      trigger: 'manual'
    })
  },

  methods: {
    async submit () {
      if (this.mode === 'create' && this.user.exceeded_status_pages_limit) {
        this.showLimitExceeded()

        return false
      }

      const valid = await this.$refs.observer.validate()
      if (!valid) {
        return false
      }

      this.saving = true

      this.statusPageData.uptime_threshold = this.uptimeThreshold / 100

      let statusPage

      try {
        this.error = false
        if (this.mode === 'create') {
          statusPage = await statusPagesApi.store(this.statusPageData)
        } else {
          statusPage = await statusPagesApi.update(
            this.statusPage.id,
            this.statusPageData
          )
        }

        await this.refreshCurrentUser()

        this.showUpdateSuccess()
        this.$emit('submitSuccess', statusPage)
      } catch (error) {
        if (error.response.data.message.includes('domain')) {
          this.error = error.response.data.message
        } else {
          this.error = 'Something went wrong'
        }
      }

      // Update number of status pages in order to control onboarding screen

      this.saving = false
    },

    // TODO: Implement timezones
    async loadTimeZones () {
      const timeZones = await timeZoneApi.get()

      this.timeZones = timeZones.map(zone => ({
        id: zone,
        title: zone
      }))

      // TODO: Later we should check user timezone set in profile since he can change it on purpose
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

      this.selectedTimeZone = this.timeZones.find(zone => zone.id === userTimeZone)
    },

    showLimitExceeded () {
      if (this.limitExceededTippy) {
        this.limitExceededTippy.destroy()
      }

      this.limitExceededTippy = tippy(this.$refs.submit.$el, {
        allowHTML: true,
        content: this.$refs.limitExceeded.$el,
        interactive: true,
        arrow: false,
        placement: 'top-start',
        trigger: 'manual',
        theme: 'big-padding',
        animation: 'shift-away-extreme'
      })

      this.limitExceededTippy.show()
    },

    showUpdateSuccess () {
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    goToStatusPagesList () {
      this.$router.push({
        name: 'statusPages.list'
      })
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    submitLabel () {
      if (this.mode === 'create') {
        return 'Save'
      } else {
        return 'Update'
      }
    },

    ...mapState('authentication', [
      'user'
    ])
  },

  watch: {
    monitors () {
      this.statusPageData.monitors = this.monitors.map(m => m.id)
    },

    selectedTimeZone () {
      this.statusPageData.timezone = this.selectedTimeZone ? this.selectedTimeZone.id : null
    }
  }
}
</script>

<style lang="scss" scoped>
.StatusPageForm {
  .header {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .logo-preview {
    display: flex;
    //flex-direction: column;
    align-items: center;
    margin-top: 18px;

    .logo {
      max-width: 110px;
      margin-right: 10px;
      border-radius: 5px;
      padding: 5px;
      background-color: #19221d;
      max-height: 46px;
    }
  }

  .label {
    display: block;
    margin-bottom: 6px;
    color: map-get($colors, gray-1);
    font-size: 14px;
    font-weight: 600;
  }

  .pro-label {
    margin: 3px 0 0 8px;
    height: min-content;
    padding: 0px 6px;
    border: 1px solid #b1b1b1;
    border-radius: 43px;
    font-size: 10px;
    color: #fff;
    background-color: #b1b1b1;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  .checkbox-description {
    font-size: 12px;
    padding-left: 30px;
    margin-top: -17px;
    margin-bottom: 20px;
    opacity: 0.6;
    line-height: 17px;
  }

  .hint-icon {
    margin: 3px 0 0 5px;
  }

  .toolbar {
    position: sticky;
    bottom:0;
    background: rgba(255,255,255, 0.9);
    padding: 15px 0;
    backdrop-filter: blur(3px);
  }
}
</style>
